import React from 'react';

import Typography from 'components/uiLibrary/Typography';
import GlobalSearch from 'components/Globals/Layout/Header/GlobalSearch';
import HeaderSearch from 'components/Globals/Layout/Header/HeaderSearch';
import Image from 'components/uiLibrary/Image';

import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { useTranslation } from 'src/i18n';

import { TP } from 'constants/index';

import LogoImage from 'public/images/logo_white.png';

import classes from './SearchBg.module.scss';

const SearchBg = () => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { isDesktop } = useDeviceTypeLayouts();

  return (
    <div className={classes.searchBg}>
      <Image
        src="https://images.operabase.com/image/fetch/w_3840/https://public.operabase.com/images/banners/operabase_bg_20241022T113632524Z.webp"
        srcSet={[
          {
            path:
              'https://images.operabase.com/image/fetch/f_avif,w_640/https://public.operabase.com/images/banners/operabase_bg_20241022T113632524Z.webp',
            type: 'image/avif',
            width: 640,
          },
          {
            path:
              'https://images.operabase.com/image/fetch/f_avif,w_750/https://public.operabase.com/images/banners/operabase_bg_20241022T113632524Z.webp',
            type: 'image/avif',
            width: 750,
          },
          {
            path:
              'https://images.operabase.com/image/fetch/f_avif,w_828/https://public.operabase.com/images/banners/operabase_bg_20241022T113632524Z.webp',
            type: 'image/avif',
            width: 828,
          },
          {
            path:
              'https://images.operabase.com/image/fetch/f_avif,w_1080/https://public.operabase.com/images/banners/operabase_bg_20241022T113632524Z.webp',
            type: 'image/avif',
            width: 1080,
          },
          {
            path:
              'https://images.operabase.com/image/fetch/f_avif,w_1200/https://public.operabase.com/images/banners/operabase_bg_20241022T113632524Z.webp',
            type: 'image/avif',
            width: 1200,
          },
          {
            path:
              'https://images.operabase.com/image/fetch/f_avif,w_1280/https://public.operabase.com/images/banners/operabase_bg_20241022T113632524Z.webp',
            type: 'image/avif',
          },
          {
            path:
              'https://images.operabase.com/image/fetch/f_webp,w_640/https://public.operabase.com/images/banners/operabase_bg_20241022T113632524Z.webp',
            type: 'image/webp',
            width: 640,
          },
          {
            path:
              'https://images.operabase.com/image/fetch/f_webp,w_750/https://public.operabase.com/images/banners/operabase_bg_20241022T113632524Z.webp',
            type: 'image/webp',
            width: 750,
          },
          {
            path:
              'https://images.operabase.com/image/fetch/f_webp,w_828/https://public.operabase.com/images/banners/operabase_bg_20241022T113632524Z.webp',
            type: 'image/webp',
            width: 828,
          },
          {
            path:
              'https://images.operabase.com/image/fetch/f_webp,w_1080/https://public.operabase.com/images/banners/operabase_bg_20241022T113632524Z.webp',
            type: 'image/webp',
            width: 1080,
          },
          {
            path:
              'https://images.operabase.com/image/fetch/f_webp,w_1200/https://public.operabase.com/images/banners/operabase_bg_20241022T113632524Z.webp',
            type: 'image/webp',
            width: 1200,
          },
          {
            path:
              'https://images.operabase.com/image/fetch/f_webp,w_1280/https://public.operabase.com/images/banners/operabase_bg_20241022T113632524Z.webp',
            type: 'image/webp',
          },
        ]}
        alt="Operabase Banner"
        lazy={false}
        disableCloudinary
        disableNextImage
        preload
        className={classes.bg}
      />
      <div className={classes.mainContent}>
        <Image
          src={LogoImage}
          alt="Operabase Home"
          lazy={false}
          height={isDesktop ? 24 : 17}
          disableCloudinary
          disableNextImage
        />
        <Typography variant="h1" color="white" size={isDesktop ? 20 : 12} secondaryFont className={classes.mainHeading}>
          {t(`${TP}.OB_MAIN_HEADING`)}
        </Typography>
        <div className={classes.searchContainer}>
          {isDesktop && (
            <div className={classes.oldGlobalSearch}>
              <HeaderSearch />
            </div>
          )}
          <GlobalSearch />
        </div>
      </div>
    </div>
  );
};

export default SearchBg;
